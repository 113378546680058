<template>
  <form
    v-if="!isLoading"
    @submit.prevent="handleSubmit"
    v-shortkey="shortkeys"
    @shortkey="triggerShortkey"
    class="tx_wrapper container P-4"
  >
    <div class="row mb-2 px-4 align-items-start">
      <label for="billingTransactionCodeId" id="billingTransactionCodeIdlabel" class="col-3"
        ><b>Transaction Code:</b></label
      >
      <SelectInput
        :noLabel="true"
        class="col-5"
        v-model="transaction.billingTransactionCodeId"
        :allow-empty="false"
        placeholder="Select a code."
        :displayExpr="displayTxCode"
        valueExpr="id"
        name="billingTransactionCodeId"
        id="billingTransactionCodeId"
        ref="billingTransactionCodeId"
        data-testid="billingTransactionCodeId"
        :searchExpr="searchBillingCodeExpr"
        :validator="$v.transaction.billingTransactionCodeId"
        :dataSource="transactionCodesSearch"
      />
      <span class="mx-2 code-description"
        ><b>{{ codeDescription }}</b></span
      >
    </div>
    <div class="row mb-2 px-4">
      <div for="" class="col mb-2"><b>Specimen Diagnosis:</b></div>
      <DxDataGrid
        :keyboardNavigation="{
          editOnKeyPress: true,
          enterKeyDirection: 'startEdit'
        }"
        @row-updated="calculateUnits"
        ref="dataGrid"
        class="col"
        :columns="specimenTableColumns"
        :summary="gridSummary"
        :data-source="transaction.specimens"
        :editing="editing"
        :hoverStateEnabled="true"
        :column-auto-width="true"
        hint="Click the quantity cell to edit."
        noDataText="This accession has no specimens."
        :cacheEnabled="false"
        @initialized="initGrid"
      >
        <template v-slot:quantityTemplate="{ data }">
          <input
            :value="data.value"
            type="number"
            :noLabel="true"
            :step="1"
            :min="0"
            class="small-input form-control"
            :name="data.column.dataField"
            :data-row-index="data.rowIndex"
            @change="data.setValue($event.target.value)"
            @input="handleChangeQuantity($event, data)"
          />
        </template>
      </DxDataGrid>
    </div>
    <div v-if="hasTxCode" class="row">
      <div class="col-5 offset-3 d-flex flex-column">
        <span class="small text-muted mb-2"><b>Click the quantity cell to edit. </b></span>
        <div v-if="$v.units.$invalid">
          <div
            class="validation-error"
            v-for="(key, index) in Object.keys($v.units.$params)"
            :key="index"
          >
            <span class="small text-danger mb-2 error" v-if="!$v.units[key]">
              {{ validatorMsgMap[key] }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="container px-4">
      <div class="row">
        <span class="offset-3 small col-5"><b>Bill Type: </b> {{ billingTypeDisplay }}</span>
        <span class="col-3 small"><b>Bill Cycle: </b> {{ billingCycle }}</span>
      </div>
      <div class="row mb-2 align-items-start">
        <label for="" class="col-3"
          ><b><u>T</u>ransaction Date: </b></label
        >
        <date-picker
          :validator="$v.transaction.occurredOn"
          class="col-5"
          ref="occuredOn"
          name="occuredOn"
          v-model="transaction.occurredOn"
          dateSerializationFormat="yyyy-MM-dd"
        />
        <div class="col">
          <span class="small"><b>Posted: </b></span>
          <span class="small">{{ postedOn ? formatDate(postedOn) : "N/A" }}</span>
        </div>
      </div>
      <div class="row align-items-start mb-2">
        <label for="billingRateId" id="billingRateIdlabel" class="col-3"><b>Rat<u>e</u>:</b></label>
        <select-input
          v-model="transaction.billingRateId"
          :items="billingRates"
          :noLabel="true"
          class="col-5"
          name="billingRateId"
          id="billingRateId"
          ref="billingRateId"
          label="billing rate"
          data-testid="billingRateId"
        />
        <span v-if="hasTxCode" class="col-3 small"><b>Rate: </b> {{ unitPriceCurrency }}</span>
      </div>
      <div class="row align-items-center mb-3">
        <label for="amount" id="amountlabel" class="col-3"
          ><b><u>A</u>mount: </b></label
        >
        <currency-input
          name="amount"
          id="amount"
          ref="currencyInput"
          v-model="transaction.amount"
          :validator="$v.transaction.amount"
          :noLabel="true"
          class="col-5 hide_arrows"
        />
      </div>
      <div class="row my-2">
        <div class="col-3"></div>
        <checkbox
          id="send"
          class="ml-3"
          :label="`Send (${dateSent})`"
          v-model="transaction.sendFlag"
        />
      </div>
    </div>
    <div class="row justify-content-end mx-2">
      <button @click="$emit('close')" class="btn btn-danger" type="button">Cancel</button>
      <button
        :disabled="$v.transaction.$invalid || !permissions.CaseTransactionCreateEdit"
        class="btn btn-primary mx-2"
        type="submit"
      >
        Save
      </button>
    </div>
  </form>
  <loader class="m-auto" v-else></loader>
</template>

<script>
import BillingApi from "@/services/Billing";
import { mapState, mapGetters } from "vuex";
import { DxDataGrid } from "devextreme-vue/data-grid";
import { parseISO, format, isValid } from "date-fns";
import {
  createLogComment,
  createLogItem,
  dateRangeFilter,
  getAltKeys,
  noFutureDate
} from "@/modules/helpers";
import { minValue, required } from "vuelidate/lib/validators";
import CurrencyInput from "@/components/CurrencyInput.vue";
import { validatorMsgMapBase } from "@/modules/helpers.js";
import { formatDatetimeCell, getTextFromHtml } from "../../../modules/helpers";
import DataSource from "devextreme/data/data_source";
import Checkbox from "@/components/common/Checkbox.vue";
import SelectInput from "@/components/common/SelectInput.vue";
import {
  TransactionsApi,
  DropdownApi,
  CasesApi,
  SpecimensApi,
  AuditLogApi,
  InsuranceApi
} from "@/services";
import DatePicker from "@/components/common/DatePicker.vue";
import Loader from "@/components/common/Loader.vue";
import { AuditLogItems, BillingTypeEnum } from "@/modules/enums";
import { handleErrors } from "@/modules/handleErrors";
import { sortBy } from "lodash";

export default {
  props: {
    txId: {
      required: true
    },
    currentTab: {
      type: Number,
      default() {
        return 0;
      }
    },
    caseId: {
      type: Number,
      required: true
    },
    autoCheckSend: {
      default: false
    }
  },
  components: {
    DxDataGrid,
    CurrencyInput,
    Checkbox,
    SelectInput,
    DatePicker,
    Loader
  },
  data() {
    return {
      searchBillingCodeExpr: ["code", "description"],
      caseDetails: {},
      editing: {
        allowUpdating: true,
        mode: "cell"
      },
      shortkeys: getAltKeys("aets"),
      billingCycles: [],
      caseTransactions: [],
      transactionCodes: [],
      hasTxCode: false,
      gridSummary: {
        totalItems: [
          {
            name: "Total",
            summaryType: "sum",
            column: "quantity",
            showInColumn: "Total",
            displayFormat: "Total: {0}"
          }
        ],
        recalculateWhileEditing: true
      },
      isLoading: false,
      units: 0,
      specimenRateId: null,
      txCode: null,
      hasQty: false,
      defaultTx: {
        caseId: 0,
        billingTransactionCodeId: null,
        billingTransactionCode: null,
        specimens: [],
        billingType: "",
        billingCycle: "",
        occurredOn: new Date(),
        postedOn: new Date(),
        billingRateId: null,
        rate: null,
        amount: 0,
        lastSentOn: null,
        sendFlag: false
      },
      transaction: {
        caseId: 0,
        billingTransactionCodeId: null,
        billingTransactionCode: null,
        specimens: [],
        billingType: "",
        billingCycle: "",
        occurredOn: new Date(),
        postedOn: new Date(),
        billingRateId: null,
        rate: null,
        amount: 0,
        lastSentOn: null,
        sendFlag: false,
        grid: {}
      },
      labBillingRates: [],
      cptBillingRates: []
    };
  },
  validations() {
    return {
      transaction: {
        billingTransactionCodeId: {
          required
        },
        occurredOn: {
          noFutureDate
        },
        rate: {
          required
        },
        amount: {
          required: value => {
            return this.txCode?.isAmountRequired ? value > 0 : true;
          }
        }
      },
      units: {
        quantity: minValue(1)
      }
    };
  },
  created() {
    DropdownApi.getBillingRates().then(res => {
      this.labBillingRates = res || [];
    });
    DropdownApi.getBillingCycles().then(res => {
      this.billingCycles = res || [];
    });
    TransactionsApi.transactionsStore
      .load({
        filter: ["caseId", this.caseId]
      })
      .then(caseTransactions => {
        this.caseTransactions = caseTransactions || [];
      });
    this.loadTxData();
    if (this.autoCheckSend) {
      this.transaction.sendFlag = true;
    }
  },

  computed: {
    ...mapState({
      currentCaseDetails: state => state.accessionStore.caseDetails,
      DefaultBillingRate: state => state.labSettings.DefaultBillingRate,
      specimens: state => state.accessionStore.specimens,
      insurances: state => state.accessionStore.insurances
    }),
    ...mapGetters(["permissions"]),

    transactionCodesSearch() {
      return new DataSource({
        store: BillingApi.searchStore,
        filter: dateRangeFilter(),
        sort: ["code"]
      });
    },
    invalidTxCodes() {
      if (this.caseTransactions.length) {
        return this.caseTransactions
          .filter(tx => tx.id !== this.txId)
          .map(e => e.transactionCodeId);
      }
      return [];
    },

    billingCycle() {
      const { billingCycle } = this.caseDetails;
      if (billingCycle) {
        const BillingCycle = this.billingCycles?.find(e => e?.id === billingCycle);
        if (BillingCycle) {
          return BillingCycle.displayName;
        }
      }
      return "";
    },
    postedOn() {
      const { recordedOn } = this.transaction;
      if (recordedOn) {
        return recordedOn;
      }
      return "";
    },
    billingTypeDisplay() {
      if (this.caseDetails && this.caseDetails.billingType != undefined) {
        return this.caseDetails.billingType === 1 ? "Doctor" : "Patient";
      }
      if (this.transaction.billingType) {
        return this.transaction.billingType;
      }
      return "N/A";
    },
    currentRate() {
      if (this.transaction.billingRateId != undefined) {
        return this.billingRates.find(e => e.id === this.transaction.billingRateId);
      }
      return null;
    },
    unitPrice() {
      if (this.units && this.currentRate) {
        const pricePoint = this.currentRate?.pricePoints?.find(e => {
          return this.units === e.minQty || this.units > e.minQty;
        });
        return pricePoint?.unitPrice;
      }
      return null;
    },
    unitPriceCurrency() {
      if (this.unitPrice) {
        return this.formatCurrency(this.unitPrice);
      }
      return null;
    },
    specimenTableColumns() {
      return [
        {
          dataField: "num",
          caption: "Specimen",
          allowEditing: false
        },
        {
          dataField: "diagnosis",
          dataType: "string",
          calculateCellValue: data => data.diagnosis && getTextFromHtml(data.diagnosis),
          width: "300px"
        },
        {
          dataField: "quantity",
          catpion: "# of Units",
          dataType: "number",
          editorOptions: {
            dataType: "number",
            showClearButton: false
          },
          editCellTemplate: "quantityTemplate",
          showEditorAlways: true,
          validationRules: [
            {
              type: "compare",
              comparisonTarget: () => -1,
              comparisonType: ">",
              ignoreEmptyValue: false,
              message: "Value cannot be negative."
            },
            {
              type: "compare",
              comparisonTarget: () => 1000,
              comparisonType: "<",
              ignoreEmptyValue: false,
              message: "Value is too high."
            },
            {
              type: "pattern",
              pattern: "^[0-9]+$",
              message: "Do not use letters in quantity."
            }
          ]
        }
      ];
    },
    codeDescription() {
      if (this.txCode) {
        return this.txCode.description;
      }
      return "";
    },
    dateSent() {
      if (this.transaction?.lastSentOn) {
        let date = parseISO(this.transaction.lastSentOn);
        if (isValid(date)) {
          return `last sent: ${format(date, "MM/dd/yyyy hh:mm a")}`;
        } else {
          date = this.transaction.lastSentOn;
        }
        return `last sent: ${format(date, "MM/dd/yyy hh:mm a")}`;
      }
      return "has never been sent.";
    },
    validatorMsgMap() {
      return {
        validatorMsgMapBase,
        quantity: "Must have at least one specimen unit."
      };
    },
    billingRates() {
      let billingRates = [];
      if (this.cptBillingRates.length) {
        billingRates = this.cptBillingRates;
      } else {
        billingRates = this.labBillingRates;
      }
      return sortBy(billingRates, "displayName");
    }
  },
  watch: {
    units(value) {
      if (value && this.unitPrice) {
        this.transaction.amount = parseFloat(value * this.unitPrice);
      }
    },
    unitPrice(value) {
      if (value && this.units) {
        this.transaction.rate = value;
        const calculatedAmount = value * this.units;
        return (this.transaction.amount = calculatedAmount);
      }
    },
    "transaction.rate": function (value) {
      if (value && this.units && this.unitPrice) {
        this.transaction.amount = this.units * this.unitPrice;
      }
    },
    "transaction.billingRateId"(value) {
      if (value === null && !this.txId) {
        this.transaction.amount = 0;
      }
    },
    "transaction.billingTransactionCodeId": {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          if (this.invalidTxCodes.includes(newValue)) {
            window.notify(
              "This transaction code has already been used within this case.",
              "warning",
              3500,
              {
                at: "top center",
                of: ".modal__container"
              }
            );
            this.transaction.billingTransactionCodeId = "";
            this.txCode = null;
            return;
          }
          this.cptBillingRates = await BillingApi.getTransactionCodeRates(newValue);
          if (!this.cptBillingRates.find(e => e.id === this.transaction.billingRateId)) {
            this.transaction.billingRateId = this.cptBillingRates[0]?.id || null;
          }
          if (!this.transaction.billingRateId) {
            this.transaction.billingRateId = this.cptBillingRates[0].id;
          }
          this.txCode = await BillingApi.getTransactionCode(newValue);
          this.hasTxCode = true;
        } else {
          this.txCode = null;
          this.hasTxCode = false;
          this.transaction.rate = null;
          this.transaction.billingRateId = null;
          this.transaction.amount = 0;
        }
      }
    }
  },
  methods: {
    displayTxCode(data) {
      return data && `${data.code}-${data.description}`;
    },
    calculateUnits() {
      this.units = this.transaction?.specimens?.reduce((acc, curr) => {
        return acc + (Number(curr?.quantity) || 0);
      }, 0);
    },
    triggerShortkey(event) {
      switch (event.srcKey) {
        case "t":
          this.$refs.occurredOn.focus();
          break;
        case "e":
          this.$refs.billingRateId.focus();
          break;
        case "a":
          this.$refs.currencyInput.focus();
          break;
        case "s":
          this.handleSubmit();
          break;
        default:
          break;
      }
    },
    async loadTxData() {
      this.isLoading = true;
      try {
        let specimens = this.specimens;
        if (this.caseId === this.currentCaseDetails.caseId) {
          this.caseDetails = this.currentCaseDetails;
        } else {
          this.caseDetails = await CasesApi.getCaseById(this.caseId);
          specimens = await SpecimensApi.getSpecimen(this.caseId);
        }
        if (this.txId) {
          this.transaction = await TransactionsApi.getTransactionById(this.txId);
          this.isLoading = false;
        } else {
          //! This setting needs to be added.
          this.loadBillingRate();
          this.transaction.occurredOn = this.caseDetails.collectedOn;

          this.transaction.specimens = specimens.map(specimen => {
            const { id, diagnosis, specimenOrder } = specimen;
            return {
              id,
              diagnosis: diagnosis || "",
              quantity: 1,
              num: specimenOrder
            };
          });
        }
        this.$nextTick(() => {
          if (!this.transaction?.billingTransactionCodeId) {
            this.$refs.billingTransactionCodeId.focus();
          } else {
            this.$refs.billingRateId.focus();
          }
        });
      } catch (error) {
        window.notify("Error loading tx", "error");
      }
      this.calculateUnits();
      this.isLoading = false;
    },
    getTextFromHtml,
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.notify("Please verify your input and try again.", "warning");

        return;
      }

      try {
        this.isLoading = true;
        const payload = { ...this.transaction, caseId: this.caseId };
        const logItem = createLogItem(this.caseDetails, AuditLogItems.ChangeAccession);
        let response;
        if (this.transaction.id) {
          const originalTx = await TransactionsApi.getTransactionById(this.transaction.id);
          logItem.comments = createLogComment(originalTx, this.transaction);
          response = await TransactionsApi.updateTx(payload);
        } else {
          logItem.comments = `Added a new transaction: ${this.txCode.code}`;
          response = await TransactionsApi.insertTx(payload);
        }
        await AuditLogApi.insertLogMessage(logItem);
        this.isLoading = false;
        if (response?.validationErrors?.length) {
          const message = response?.validationErrors.join("\n");
          alert(message);
        } else {
          if (payload.caseId === this.currentCaseDetails.caseId) {
            await this.$store.dispatch("accessionStore/getCaseCounts", this.caseId);
          }
          this.$emit("submit", response);
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    txCodeTag(data) {
      const { code, billingTxnCodeType } = data;
      return `${code}${billingTxnCodeType ? `(${billingTxnCodeType})` : ""}`;
    },

    formatDate(data) {
      if (!data) {
        return "";
      }
      if (data instanceof Date) {
        return format(formatDatetimeCell(data), "MM/dd/yyy hh:mm a");
      } else if (isValid(new Date(data))) {
        return format(formatDatetimeCell(data), "MM/dd/yyy hh:mm a");
      }
    },
    formatCurrency(number) {
      if (isNaN(number)) {
        return number;
      }
      const currency = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(Number(number));
      return currency;
    },
    handleChangeQuantity(event, data) {
      event.stopPropagation();
      data.setValue(event.target.value);
      this.grid.refresh();
    },
    initGrid({ component }) {
      this.grid = component;
    },
    async loadBillingRate() {
      const { billingType } = this.caseDetails;
      if (billingType === BillingTypeEnum.Patient) {
        if (this.insurances.length) {
          const primaryInsurancePolicy = this.insurances.find(e => e.insuranceType === 1);
          if (primaryInsurancePolicy) {
            const insuranceDetails = await InsuranceApi.searchStore.load({
              filter: ["id", "=", primaryInsurancePolicy.insuranceId]
            });
            if (insuranceDetails[0]?.billingRate?.id) {
              const insuranceBillingRate = insuranceDetails[0]?.billingRate;
              if (!this.billingRates?.find(e => e.id === insuranceBillingRate.id)) {
                this.labBillingRates = { ...this.labBillingRates, insuranceBillingRate };
              }
              this.transaction.billingRateId = insuranceBillingRate.id;
              return;
            }
          }
        }
      }
      if (billingType === BillingTypeEnum.Doctor) {
        const { contact } = this.caseDetails?.contacts.find(e => e.isPrimary);
        if (contact?.properties?.billingRateNumber) {
          this.transaction.billingRateId = contact?.properties?.billingRateNumber;
          return;
        }
      }
      if (this.DefaultBillingRate) {
        this.transaction.billingRateId = this.DefaultBillingRate;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tx_wrapper {
  width: 700px;
}
.text-input {
  min-height: initial;
}
.row {
  align-items: center;
}
.small {
  font-size: 0.78rem;
}
::v-deep div.dx-revert-button.dx-button {
  display: none;
}
.code-description {
  font-size: 0.75rem;
}
</style>
